<!--
 * @Description: 停车管理--停车卡管理--列表页面
 * @Author: 小广
 * @Date: 2019-06-04 14:06:49
 * @LastEditors: 蒙川
 * @LastEditTime: 2019-09-02 11:36:25
 -->
<template>
  <div class="parkCardManageList-wrapper">
    <list
      ref="list"
      exportPermission="export"
      :searchUrl="searchUrl"
      :exportUrl="exportUrl"
      :searchParams.sync="searchParams"
      :headers="headers"
      @select="select"
      @selectAll="selectAll"
      :selectable="selectable"
    >
      <template #headerSlot>
        <v-button text="新增" permission="add" @click="create"></v-button>
        <!-- <v-button text="批量审核" permission="add" @click="handleBatchCheck"></v-button> -->
      </template>
      <template #searchSlot>
        <v-input label="用户姓名" v-model="searchParams.userName"></v-input>
        <v-input label="用户手机号" v-model="searchParams.phoneNum"></v-input>
        <v-input label="车位号" v-model="searchParams.carRoomName"></v-input>
        <!-- <v-input label="卡面编号" v-model="searchParams.cardNum"></v-input> -->

        <v-select
          label="车位状态"
          v-model="searchParams.status"
          :options="carCardStatusOps"
        ></v-select>
        <v-select2
          label="套餐名称"
          v-model="searchParams.suitId"
          v-bind="suitNameParams"
        ></v-select2>
        <v-input label="车牌号" v-model="searchParams.carNum"></v-input>
        <!-- <v-select
          label="审核状态"
          v-model="searchParams.auditStatus"
          :options="stopTypeOps"
        ></v-select> -->
        <!-- <v-select
          label="租赁方式"
          v-model="searchParams.rentType"
          :options="rentTypeOps"
        ></v-select> -->
         <!-- <v-select2
          label="所属项目"
          v-model="searchParams.communityId"
          v-bind="communityParams"
        ></v-select2> -->
        <!-- <v-datepicker
          label="提交时间"
          :startTime.sync="searchParams.inDateFrom"
          :endTime.sync="searchParams.inDateTo"
          type="rangedatetimer"
          defaultTimeType="week"
        ></v-datepicker> -->
        <v-datepicker
          label="提交时间"
          :startTime.sync="searchParams.inDateFrom"
          :endTime.sync="searchParams.inDateTo"
          type="rangedatetimer"
        ></v-datepicker>
        <!-- <v-datepicker
          label="审核时间"
          :startTime.sync="searchParams.startDateFrom"
          :endTime.sync="searchParams.startDateTo"
          type="rangedatetimer"
        ></v-datepicker> -->
        <v-datepicker
          label="到期时间"
          :startTime.sync="searchParams.endDateFrom"
          :endTime.sync="searchParams.endDateTo"
          type="rangedatetimer"
        ></v-datepicker>
         <!-- <v-select
          label="状态"
          v-model="searchParams.status"
          :options="carCardStatusOps"
        ></v-select> -->
      </template>
      <template #operateSlot="scope">
        <!-- 研发环境下不显示以下按钮，本地没毛病，加上这个空 div 研发环境就没毛病了，神奇！！！ -->
        <div> </div>
        <!-- <v-button
          v-if="scope.row.auditStatus === 1 && scope.row.status !== 3"
          text="审核"
          type="text"
          permission="update"
          @click="check(scope.row)"
        ></v-button> -->
        <v-button
          v-if="scope.row.status !== 3"
          text="设置"
          type="text"
          permission="update"
          @click="edit(scope.row)"
        ></v-button>
        <v-button
          v-if="scope.row.status !== 3"
          text="关闭车位"
          type="text"
          permission="update"
          @click="close(scope.row)"
        ></v-button>
      </template>
    </list>
    <el-dialog title="停车授权批量审核" :visible.sync="dialogFormVisibleBatch" class="dialog-check">
      <el-form :model="form" ref="formRef">
        <el-form-item
          label="审核状态"
          :rules="[ { required: true, message: '当前选项不允许为空',  trigger: 'change'  }  ]"
        >
          <v-select
            v-model="form.batchStatus"
            :options="batchCheckOps"
          ></v-select>
        </el-form-item>
        <el-form-item 
          label="备注"          
          prop="batchRemarks"
          :rules="[ { required: true, message: '当前选项不允许为空',  trigger: 'change'  }  ]"
        >
          <v-textarea v-model="form.batchRemarks" placeholder="请输入备注信息" :width="450"></v-textarea>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisibleBatch = false">取 消</el-button>
        <el-button type="primary" @click="handleSubmitBatchCheck">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog center title="停车授权审核" :visible.sync="dialogFormVisibleSingle" class="dialog-check">
      <div>
        <col2-detail>
          <col2-block title="车主信息">
            <col2-item label="姓名" prop="userName" :text="checkMsg.userName" />
            <col2-item label="手机号" prop="mobileNum" :text="checkMsg.mobileNum" />
            <col2-item label="证件号" prop="userCardNum" :text="checkMsg.userCardNum" />
            <col2-item label="项目" prop="communityName" :text="checkMsg.communityName" />
            <col2-item label="房号" prop="houseName" :text="checkMsg.houseName" />
          </col2-block>
          <col2-block title="车辆信息">
            <template v-for="(item, index) in checkMsg.carNumList">
              <div :key="index">
                <col2-item label="车牌" prop="plateNumber" :text="item.plateNumber"/>
                <col2-item label="品牌" prop="carBrand" :text="item.carBrand"/>
                <col2-item label="颜色" prop="carColor" :text="item.carColor"/>
                <col2-item label="行驶证图片" prop="drivingLicensePic">
                  <v-preview :imgs="item.drivingLicensePic"></v-preview>
                </col2-item>
              </div>
            </template>
          </col2-block>
          <col2-block title="停车信息">
            <col2-item label="车场-车位号" prop="carOrZoneName" :text="checkMsg.carOrZoneName" />
            <col2-item label="包月" prop="suitName" :text="checkMsg.suitName" />
          </col2-block>
          <col2-block>
            <checkbox-plus type="radio" :options="suitTypeOpts" :value.sync="checkMsg.suitType"></checkbox-plus>
            <v-textarea v-model="checkMsg.auditRemarks" placeholder="请输入审核意见" :width="600"></v-textarea>
          </col2-block>
        </col2-detail>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisibleSingle = false">取 消</el-button>
        <el-button type="primary" @click="handleComfirmCheck">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getCardListURL, exportListURL, closeCarParkCardURL, processCheck, getSuitByLocationURL  } from './api'
import { vPreview } from 'components/control'
import { Col2Item, CheckboxPlus, Col2Block, Col2Detail  } from 'components/bussiness'
import {
  carCardStatusOps,
  carCardStatusMap,
  rentTypeOps,
  rentTypeMap,
  stopTypeOps,
  stopTypeMap,
  batchCheckOps
} from './map'
import { communityParams } from 'common/select2Params'

export default {
  name: 'ParkOrderManageList',
  components: {
    CheckboxPlus,
    Col2Item,
    Col2Block,
    Col2Detail,
    vPreview
  },
  data () {
    return {
      batchRemarks: '', // 批量审核备注信息
      batchStatus: 1, // 批量审核状态 0 不通过， 1 通过
      batchCheckOps: batchCheckOps,
      stopTypeOps: stopTypeOps,
      form: {
        batchStatus: 1,
        batchRemarks: ''
      },
      checkMsg: {},
      suitTypeOpts: [{
        label: '通过',
        value: 1
      }, {
        label: '不通过',
        value: 2
      }],
      suitNameParams: {
        searchUrl: getSuitByLocationURL,
        request: {
          text: "suitName",
          value: "suitId",
        },
        response: {
          text: "suitName",
          value: "id",
        },
      },
      dialogFormVisibleBatch: false,
      dialogFormVisibleSingle: false,
      searchUrl: getCardListURL,
      exportUrl: exportListURL,
      carCardStatusOps: this.getCarCardStatusOps(),
      rentTypeOps: rentTypeOps,
      communityParams,
      searchParams: {
        communityId: '',
        auditStatus: undefined,
        status: undefined,
        cardNum: '',
        carNum: '',
        userName: '',
        phoneNum: '',
        startTime: '',
        endTime: '',
        carRoomName: '',
        suitId: ''
      },
      headers: [
        {
          prop: 'mobileNum',
          label: '用户手机号'
        },
        {
          prop: 'userName',
          label: '用户姓名'
        },
        {
          prop: 'communityName',
          label: '项目'
        },
        {
          prop: 'carOrZoneName',
          label: '车位号'
        },
        {
          prop: 'status',
          label: '车位状态',
          formatter: (row) => {
            return carCardStatusMap[row.status]
          },
        },
        {
          prop: 'suitName',
          label: '套餐名称'
        },
        {
          prop: 'carNums',
          label: '车牌号'
        },
        // {
        //   prop: 'status',
        //   label: '审核状态',
        //   formatter: (row) => {
        //     // if (row.status == 2 || row.status == 4) {
        //       return stopTypeMap[row.auditStatus]
        //     // } else {
        //     //   return '不可停'
        //     // }
        //   } 
        // },
        {
          prop: 'intime',
          label: '提交时间'
        },
        {
          prop: 'endTime',
          label: '到期时间'
        },
        {
          prop: 'remark',
          label: '备注'
        },
        // {
        //   prop: 'rentTypeStr',
        //   label: '租赁方式',
        //   formatter (row) {
        //     return rentTypeMap[row.rentType]
        //   }
        // },
        // {
        //   prop: 'auditUserName',
        //   label: '审核人'
        // },
        // {
        //   prop: 'auditTime',
        //   label: '审核时间'
        // },
        // {
        //   prop: 'communityName',
        //   label: '所属项目'
        // },
        // {
        //   prop: 'startTime',
        //   label: '办理时间'
        // },
        // {
        //   prop: 'endTime',
        //   label: '到期时间'
        // },
        // {
        //   prop: 'statusStr',
        //   label: '状态',
        //   formatter (row) {
        //     return carCardStatusMap[row.status]
        //   }
        // },
        // {
        //   prop: 'intime',
        //   label: '创建时间'
        // },
        // {
        //   prop: 'updateTime',
        //   label: '更新时间'
        // }
      ],
      selectedList: []
    }
  },
  methods: {

    getCarCardStatusOps () {
      let tempList = carCardStatusOps.slice(2)
      tempList.splice(0, 0, {
        text: '全部',
        value: undefined
      })
      return tempList
    },

    create () {
      this.$router.push({
        name: 'parkCardManageForm'
      })
    },

    edit (row) {
      this.$router.push({
        name: 'parkCardManageForm',
        query: {
          id: row.id
        }
      })
    },

    async close (row) {
      const { parkingName, carNums } = row
      const h = this.$createElement
      const carPosition = parkingName
      const carNum = carNums.split(',').length
      const carMessage = carNums.split(',').join(',')
      const html = `<p>关闭车位<span style="color:red">${carPosition}</span>后， <span>${carNum}</span>辆车（<span>${carMessage}</span>）将不可停放</p>`
      const status = await this.$confirm(html, {
        title: '关闭车位',
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        dangerouslyUseHTMLString: true, 
        distinguishCancelAndClose: true,
        closeOnClickModal: false,
        center: true
      })
      if (status) {
        this.actionSubmitLabel(row.id)
      }
    },

    actionSubmitLabel (id) {
      this.$axios.get(closeCarParkCardURL + `?id=${id}`).then(res => {
        if (res.status === 100) {
          // this.dialogLabelVisible = false
          this.$refs.list.searchData()
          this.$alert('关闭成功！')
        }
      })
    },

    check (row) {
      // console.log(row);
      this.dialogFormVisibleSingle = true
      this.checkMsg = row;
      this.checkMsg.carNumList.map((item,index) => {
        if (this.checkMsg.carNumList[index].drivingLicensePic instanceof Array) {
          return
        }
        
        const list = item.drivingLicensePic.split(',').map(item => {
          if (item.indexOf('http') == -1) {
            return window.location.origin + item
          } else {
            return item
          }
        })
        this.checkMsg.carNumList[index].drivingLicensePic = list
      })
      this.checkMsg.suitType = 1
    },
    select(e) {
      this.selectedList = e
    },
    selectAll(e) {
      this.selectedList = e
    },
    // 审核提交
    async handleComfirmCheck() {
      let ids = []
      ids.push(this.checkMsg.id)
      const { auditRemarks, suitType } = this.checkMsg
      const form = {
        ids: ids,
        remarks: auditRemarks,
        status: suitType
      }
      const res = await this.$axios.post(processCheck, form)
      if (res.status === 100) {
        this.$message.success('操作成功')
        this.dialogFormVisibleSingle = false
        this.$refs.list.searchData()
      } else {
        console.log(res);
      }
    },
    // 批量审核开关
    handleBatchCheck() {
      if (!this.selectedList.length) {
        this.$message.error('请选择要审核的停车卡！')
      } else {
        this.dialogFormVisibleBatch = true;
      }
    },
    // 批量审核提交
    handleSubmitBatchCheck() {
      let _this = this
      this.$refs.formRef.validate(async (valid) => {
        if (valid) {
          const ids = []
          _this.selectedList.map(item => {
            ids.push(item.id)
          })
          const remarks = _this.form.batchRemarks;
          const status = _this.form.batchStatus;
          const form = {
            ids,
            remarks,
            status
          }
          const res = await _this.$axios.post(processCheck, form)
          if (res.status === 100) {
            this.$message.success('操作成功')
            _this.dialogFormVisibleBatch = false
            _this.$refs.list.searchData()
          }
        } else {
          return
        }
      })
    },
    // 只允许审核中的列被选中
    selectable(row) {
      return row.auditStatus === 1
    }
  }
}
</script>
<style lang="scss" scoped>
.parkCardManageList-wrapper {
  ::v-deep .dialog-check {
    .el-form-item__label {
      width: 80px;
    }
    .el-form-item__content {
      text-align: left;
    }
  }
  .dialog-check {
    ::v-deep .my-gallery {
      display: flex;
    }
  }
}
</style>